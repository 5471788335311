*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
	background-color: rgb(0, 0, 0);
	text-align: center;
}

h6 {
	margin-top: 20px;
}

.container {
	z-index: 1;
	margin: 0 auto;
    max-width: 1100px;
}

#bg-text {
	padding-top: 65vh;
    margin: 0 auto;
	text-align: center;
	color: rgb(255, 255, 255);
	font-size: 35px;
	/* z-index: -1; */
}

#side-text {
	display: flex;
	word-spacing: 30px;
	justify-content: space-between;
	padding: 500px 0;
    margin: 0 auto;
	text-align: center;
	color: rgb(255, 255, 255);
	font-size: 50px;
	z-index: -1;
}

#right-text {
	display: flex;
	justify-content: right;
	padding: 300px 0;
    margin: 0 auto;
	color: rgb(255, 255, 255);
	font-size: 50px;
	z-index: -1;
}

.socials {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	z-index: 1;
}

.socials a:hover {
	text-decoration: none;
}

section {
    display: block;
    height: 100vh;
	margin: 0 25px;
    position: relative;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
	z-index: 0;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
	z-index: 0;
}

.info {
	font-family: gravesend-sans;
	font-size: 12px;
	font-weight: bold;
	top: 97%;
	margin: 0 auto;
	width: 100%;
	text-align: center;
	color: #ffffff;
	z-index: 1000;
}

.gravesend-medium {
	font-family: gravesend-sans,sans-serif;
	font-weight: 700;
	font-style: normal;
}

.gravesend-light {
	font-family: gravesend-sans,sans-serif;
	font-size: 18px;
	font-weight: 100;
	font-style: normal;
}

svg {
	display: inline-block;
	width: 250px;
	margin: 3% auto;
	padding: 0px 100px;
}

/* SVG RULES */

.hand, 
.hand-double, 
.hand-flick, 
.hand-hold, 
.hand-rock, 
.hand-tap, 
.hand-x, 
.hand-y {
	fill: #fff;
	stroke: rgb(255, 255, 255);
	stroke-width: 3px;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.arrow-down, 
.arrow-head, 
.arrow-left, 
.arrow-right, 
.arrow-up, 
.arrow-up-1, 
.arrow-up-2, 
.arrow-up-3,   
.hold-1,
.hold-2, 
.line-horizontal, 
.line-rotate, 
.line-vertical, 
.notes, 
.tap-1, 
.tap-2 {
	fill: transparent;
	stroke: rgb(255, 255, 255);
	stroke-width: 3px;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.arrow-up-2, 
.hold-1, 
.tap-1 {
	opacity: .5;
}

.arrow-up-1, 
.hold-2, 
.tap-2 {
	opacity: .25;
}

.arrow-up-3, 
.swipe-horizontal, 
.swipe-rotate, 
.swipe-vertical {
	opacity: .75;
}

.hold-1, 
.hold-2, 
.notes {
	opacity: 0;
}

canvas {
	position: fixed;
}

a {
	cursor: pointer;
	color: white;
	margin-top: 100px;
	padding: 10px;

}

/* Footer */
footer {
	display:block;
	margin: 0 auto;
	position: absolute;
}


